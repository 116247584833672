<template>
  <TsSection append-class="mb-0 mt-4">
    <!-- <TsStoreDebugger :store-state="searchResultStore.$state"></TsStoreDebugger> -->
    <div class="heading" v-if="searchType === 'direct-search'">
      <TsTypography as="h1">
        <!-- Text: Showing results for -->
        {{ useTranslation("showingResultsFor", "Resultaten weergeven voor") }}
        "{{ getSearchQueryFromRoute() }}"
      </TsTypography>
    </div>
    <!-- <div class="heading" v-if="searchType === 'brand-search'">
      <TsTypography as="h1">
        Showing results for Brand {{ algoliaStore.lastSearchedKeyword }}
      </TsTypography>
    </div> -->
    <div class="heading" v-if="searchType === 'category-search'">
      <TsTypography as="h1">
        {{
          typeof headerContent === "string"
            ? useCapitalize(headerContent.replaceAll("-", " "))
            : "Category Search"
        }}
      </TsTypography>
      <slot name="category-header"> </slot>
    </div>
  </TsSection>
  <TsSection>
    <TsRow>
      <TsColumn v-if="!isMobile" append-class="is-3">
        <TsFilter :data-testid="`search-filters-for-product`" />
      </TsColumn>
      <TsColumn>
        <TsRow append-class="m-0 items-center justify-between mb-4" no-gutter>
          <TsColumn
            class="max-w-max"
            v-if="searchResultStore.productState.status !== 'failed'"
            >{{ searchResultStore.totalProductsFound }}
            {{ useTranslation("of", "van") }}
            {{ algoliaStore.totalNumOfFilteredResults }}
            {{ ("results", "resultaten") }}</TsColumn
          >
          <TsColumn
            class="max-w-max"
            v-if="searchResultStore.productState.status === 'failed'"
            >{{
              useTranslation("zeroResultsFound", "0 resultaten gevonden")
            }}</TsColumn
          >
          <TsColumn class="max-w-max">
            <TsRow append-class="m-0 gap-2 items-center">
              <TsTypography v-if="!isMobile">{{
                useTranslation("productsPerPage", "Producten per pagina")
              }}</TsTypography>
              <TsSelectBox
                v-model="sortProductsPerPage"
                :placeholder="algoliaStore.offset"
                :options="productPerPage"
                optionLabel="name"
                append-class="w-20"
                input-class="w-0 py-2"
                @select="(option: any, index:any) => handleProductsPerPage(option, index)"
              />
            </TsRow>
          </TsColumn>
          <TsColumn class="max-w-max">
            <TsSelectBox
              v-if="!isMobile"
              v-model="sortBy"
              :options="filterSortByOptions"
              optionLabel="name"
              placeholder="Sorteer op"
              append-class="w-40"
              input-class="w-0 py-2"
              @select="(option: any) => handleSortBy(option)"
            />
          </TsColumn>
          <TsColumn append-class="max-w-max">
            <TsButton
              icon="mingcute:grid-fill"
              @click="handleProductListingLayout('grid')"
              :append-class="
                twMerge(
                  'border-none hover:text-idle-white ml-2.5',
                  product_listing_layout === 'grid'
                    ? 'bg-primary text-idle-white'
                    : 'bg-transparent text-primary'
                )
              "
              rounded
            />
            <TsButton
              icon="material-symbols:event-list-outline"
              @click="handleProductListingLayout('list')"
              :append-class="
                twMerge(
                  'border-none hover:text-idle-white',
                  product_listing_layout === 'list'
                    ? 'bg-primary text-idle-white'
                    : 'bg-transparent text-primary'
                )
              "
              rounded
            />
          </TsColumn>
        </TsRow>
        <TsRow
          class="flex flex-wrap m-0 gap-y-2 mb-4 mt-3"
          v-if="searchResultStore.selectedFilterState.length > 0"
        >
          <div v-for="(filter, key) in searchResultStore.selectedFilterState">
            <TsChip
              appendClass="py-0 text-base mb-0"
              :label="getChipLabel(filter)"
              @remove="searchResultStore.removeFilterChip(key, filter)"
            />
          </div>
        </TsRow>
        <div v-if="searchResultStore.productState.status === 'loading'">
          <TsGrid
            :mobile-cols="product_listing_grid_cols"
            :cols="product_listing_grid_cols"
            append-class="no-gutter gap-3"
          >
            <TsProductCardTransition
              v-for="skeleton in productSkeletons()"
              class="mx-2 my-2"
            ></TsProductCardTransition>
          </TsGrid>
        </div>
        <div v-if="searchResultStore.productState.status === 'success'">
          <TsGrid
            v-if="config.public.featureFlags.enableCTACollectionDelivery"
            :mobile-cols="product_listing_grid_cols"
            :cols="product_listing_grid_cols"
            append-class="no-gutter gap-3"
          >
            <TsCardProductWithCollectionDelivery
              v-for="product in searchResultStore.products"
              :layout="product_listing_layout"
              :key="product.code"
              :product="product"
              :data-testid="`search-result-for-product-${product.code}`"
            />
          </TsGrid>

          <TsGrid
            v-else-if="config.public.featureFlags.enableCTAAddToTrolleyBuyNow"
            :mobile-cols="product_listing_grid_cols"
            :cols="product_listing_grid_cols"
            append-class="no-gutter gap-3"
          >
            <TsCardProductWithCollectionDelivery
              v-for="product in searchResultStore.products"
              :layout="product_listing_layout"
              :key="product.code"
              :product="product"
              :data-testid="`search-result-for-product-${product.code}`"
            />
          </TsGrid>
          <TsColumn>
            <TsPagination
              :data-length="algoliaStore.totalNumOfFilteredResults"
              :active-page-number="algoliaStore.currentPage + 1"
              :offset="algoliaStore.offset"
              type="stepless"
              :append-class="twMerge(isDesktop && 'items-center')"
              @next="handleNextPage"
              @prev="handlePrevPage"
              @first="handleFirstPage"
              @last="(lastPage) => handleLastPage(lastPage)"
            ></TsPagination>
          </TsColumn>
        </div>
        <div v-if="searchResultStore.productState.status === 'failed'">
          <span class="text-center pt-5">
            <TsTypography as="h1">
              {{
                useTranslation(
                  "couldntFetchProducts",
                  "Kan producten niet ophalen"
                )
              }}
            </TsTypography>
          </span>
        </div>
      </TsColumn>
    </TsRow>
  </TsSection>

  <TsModalSaveListFormV2
    v-model:visible="savelistStore.modal_savelist_from_visible"
  >
  </TsModalSaveListFormV2>

  <!-- Products Recommendations Section -->

  <ClientOnly>
    <Teleport to="body">
      <TsButtonGroup
        v-if="isMobile"
        append-class="items-center fixed left-1/2 -translate-x-1/2 bottom-4 z-10"
      >
        <TsButton
          icon="flowbite:filter-outline"
          label="Filter"
          append-class="min-w-max"
          @click="mobile_filter_drawer = true"
        />

        <TsDivider
          append-class="mx-0 py-3 h-3 min-h-max before:border-[#589EDF]"
          layout="vertical"
        />

        <TsButton
          icon="pepicons-pop:sort"
          label="Sort By"
          append-class="min-w-max"
          @click="mobile_sort_by_drawer = true"
        />
      </TsButtonGroup>
    </Teleport>
  </ClientOnly>

  <!-- Mobile Filter Drawer -->
  <TsDrawer v-model:visible="mobile_filter_drawer" position="bottom">
    <TsCard
      append-class="flex flex-col p-0 bg-white min-h-screen max-h-screen"
      header-class="p-4 border-b flex justify-between items-center"
      body-class="flex-grow overflow-auto no-scrollbar pt-0"
      footer-class="px-4 py-2 border-t mt-auto flex items-center justify-center"
      no-shadow
      no-border
    >
      <template #header>
        <TsTypography as="h2" variant="h1" append-class="mb-0">
          Filter
        </TsTypography>
        <TsButton
          label="Clear all"
          variant="danger"
          outlined
          append-class="border-0 p-0"
          @click="handleResetFiltersMobile()"
        />
      </template>
      <template #content>
        <TsFilter append-class="p-0" no-border no-shadow />
      </template>
      <template #footer>
        <TsButton
          label="Close"
          outlined
          append-class="border-0 px-8 text-natural-black"
          @click="mobile_filter_drawer = false"
        />
      </template>
    </TsCard>
  </TsDrawer>

  <!-- Mobile Sort By Drawer -->
  <TsDrawer v-model:visible="mobile_sort_by_drawer" position="bottom">
    <TsCard
      append-class="flex flex-col p-0 bg-white max-h-screen"
      header-class="p-4 border-b flex justify-between items-center"
      body-class="flex-grow overflow-auto no-scrollbar pt-0"
      no-shadow
      no-border
    >
      <template #header>
        <TsTypography as="h2" variant="h1" append-class="mb-0">
          {{ useTranslation("sortBy", "Sorteer op") }}
        </TsTypography>
        <TsButton
          icon="ic:round-close"
          outlined
          append-class="border-0 p-0"
          @click="mobile_sort_by_drawer = false"
        />
      </template>
      <template #content>
        <TsTypography
          as="div"
          append-class="px-4 py-3 mb-0"
          v-for="sortByOption in filterSortByOptions"
          @click="handleSortBy(sortByOption)"
        >
          {{ sortByOption.name }}
        </TsTypography>
      </template>
    </TsCard>
  </TsDrawer>

  <!-- Products Recommendations Section -->
  <TsSection container-class="px-2">
    <TsTypography as="h2" align="center">
      {{ useTranslation("productsRecommendations", "Productenaanbevelingen") }}
    </TsTypography>

    <TsTabsCategorySelection
      data-testid="highlight-tabs"
      :tabs="products_recommendations_chips"
    />

    <TsCarouselCardProduct
      :navigation="!isMobile"
      data-testid="product-listing-recommended-product-carousel"
      :breakpoints="products.breakpoints"
      :data="products.data"
    />
  </TsSection>

  <!-- Related  Categories Section -->
  <TsSection container-class="px-2">
    <!-- <TsTypography as="h2" align="center"> Related Categories</TsTypography> -->

    <TsCarouselRelatedCategories
      data-testid="product-listing-related-categories-carousel"
    />
  </TsSection>
</template>

<script setup lang="ts">
import { defaultProducts } from "../../json/products-dummy.json";
import { twMerge } from "tailwind-merge";
import TsProductCardTransition from "./TsProductCardTransition.vue";
import { initAccordions, initFlowbite } from "flowbite";
import {
  SortByIndexMap,
  type SearchSource,
} from "~/types/algolia/algolia.type";

const { isMobile, isDesktop } = useDevice();
const searchResultStore = useSearchResultStore();
const algoliaStore = useAlgoliaStore();
const savelistStore = useSavelistStore();

const mobile_filter_drawer = ref(false);
const mobile_sort_by_drawer = ref(false);

const config = useRuntimeConfig();

const branchStore = useBranchStore();

const handleNextPage = () => {
  algoliaStore.getNextPage();
  scrollToTop();
};

const unwatch = watch(
  () => [branchStore.lastSavedBranch],
  async () => {
    handleBranchChange(route.fullPath);
  }
);

const handleBranchChange = (routePath: string) => {
  searchResultStore.selectedFilterState = algoliaStore.extractFiltersFromURL(
    route.fullPath
  );

  if (routePath.includes("brands")) {
    // brand PLP
    searchResultStore.selectedFilterState = algoliaStore.extractFiltersFromURL(
      route.fullPath
    );
    algoliaStore.currentFlowParams.currentActiveSearchFlow = "brand-search";

    algoliaStore.handleEnterClick(
      algoliaStore.currentActiveBrand,
      "brand-search",
      true,
      searchResultStore.transformSelectedFilters(),
      algoliaStore.currentActiveIndex
    );
  } else if (routePath.includes("categories")) {
    searchResultStore.selectedFilterState = algoliaStore.extractFiltersFromURL(
      route.fullPath
    );

    algoliaStore.currentFlowParams.currentActiveSearchFlow = "category-search";

    const bakeCategoryAsFilter = (subCategoryId: number) => {
      return (
        "categoryPageId:" + extractSubcategoryId(route.params.subcategoryid)
      );
    };

    function extractSubcategoryId(routeParam: string): number | null {
      if (!isNaN(Number(route.params.subcategoryid))) {
        return parseInt(route.params.subcategoryid, 10);
      }

      // If not, extract the number from a string like 'this-that-examples-178'
      const match = route.params.subcategoryid.match(/-(\d+)$/);
      if (match) {
        return parseInt(match[1], 10); // Extracted number from the string
      }

      return null; // Return null if no match is found
    }

    algoliaStore.handleEnterClick(
      "",
      "category-search",
      true,
      searchResultStore.transformSelectedFilters(),
      algoliaStore.currentActiveIndex,
      bakeCategoryAsFilter(route.params.subcategoryid),
      route.params.subcategoryname,
      route.params.subcategoryid
    );
  } else {
    searchResultStore.selectedFilterState = algoliaStore.extractFiltersFromURL(
      route.fullPath
    );
    algoliaStore.currentFlowParams.currentActiveSearchFlow = "direct-search";

    algoliaStore.handleEnterClick(
      getSearchQueryFromRoute(),
      "direct-search",
      true,
      searchResultStore.transformSelectedFilters(),
      algoliaStore.currentActiveIndex
    );
  }
};

const handlePrevPage = () => {
  algoliaStore.getPrevPage();
  scrollToTop();
};
const handleResetFiltersMobile = () => {
  mobile_filter_drawer.value = false;
  searchResultStore.resetFilters();
};
const handleFirstPage = () => {
  algoliaStore.getFirstPage();
  scrollToTop();
};

const handleLastPage = (lastPage: number) => {
  algoliaStore.getLastPage(lastPage - 1);
  scrollToTop();
};

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth", // Optional for smooth scrolling
  });
};
type Props = {
  searchType: SearchSource;
  headerContent?: any;
};

const props = withDefaults(defineProps<Props>(), {});

const route = useRoute();

const productSkeletons = () => {
  return algoliaStore.totalNumOfFilteredResults > 24
    ? 6
    : algoliaStore.totalNumOfFilteredResults;
};

const getSearchQueryFromRoute = () => {
  return route.query.q as string;
};

const getBrandnameFromRoute = () => {
  return route.params.brandname as string;
};

// Meta Data
useHead({
  title: `Toolstation | Search results for - "${getSearchQueryFromRoute()}"`,
  meta: [
    {
      name: "description",
      content: `Product listing page for ${getSearchQueryFromRoute()}`,
    },
  ],
});

// Banner Section
const banner_data = [
  {
    id: 1,
    image: "/images/product-listing-banner-desktop-1.png",
    desktopImage: "/images/product-listing-banner-desktop-1.png",
  },
  {
    id: 2,
    image: "/images/product-listing-banner-desktop-2.png",
    desktopImage: "/images/product-listing-banner-desktop-2.png",
  },
];

// Categories
const categories = [
  {
    id: 1,
    image: "/images/deals.png",
    title: "Deals",
  },
  {
    id: 2,
    image: "/images/electric-utility.png",
    title: "Electric Utility",
  },
  {
    id: 3,
    image: "/images/electricity.png",
    title: "Electricity",
  },
  {
    id: 4,
    image: "/images/hand-tool.png",
    title: "Hand Tool",
  },
  {
    id: 5,
    image: "/images/screws-tools.png",
    title: "Screws & Tools",
  },
  {
    id: 6,
    image: "/images/tools-accessories.png",
    title: "Tool Accessories",
  },
];

onMounted(async () => {
  initAccordions();
  if (useCompareStore().checkedProduts.length > 0)
    useCompareStore().isCompareRowVisible = true;
  useCompareStore().enableExpand = false;
  useTrolleyStore().getTrolleyDetails();
  await algoliaStore.createSubCategoryBreadCrumb();
  scrollToTop();
});

onUnmounted(() => {
  useCompareStore().isCompareRowVisible = false;
  algoliaStore.breadcrumb_data = [];
});

// Filters Section
const sortBy = ref();
const sortProductsPerPage = ref();
const filterSortByOptions = ref([
  { name: "Default" },
  { name: "Price (Lowest to Highest)" },
  { name: "Price (Highest to Lowest)" },
]);

const productPerPage = ref([24, 30, 48]);

const handleProductsPerPage = (option: any) => {
  if (option) {
    algoliaStore.offset = option;

    algoliaStore.handleFilterChange(
      algoliaStore.lastSearchedKeyword,
      false,
      searchResultStore.transformSelectedFilters() ?? []
    );
  }
};

const handleSortBy = (option: any) => {
  if (isMobile && mobile_sort_by_drawer.value) {
    mobile_sort_by_drawer.value = false;
  }

  if (option && option.name) {
    const sortingByIndex = SortByIndexMap[option.name];
    updatePageNumber();

    if (sortingByIndex) {
      algoliaStore.handleFilterChange(
        algoliaStore.lastSearchedKeyword,
        false,
        searchResultStore.transformSelectedFilters() ?? [],
        [],
        sortingByIndex
      );
    }
    scrollToTop();
  }
};

const updatePageNumber = () => {
  if (algoliaStore.currentPage > 0) {
    algoliaStore.currentPage = 0;
  }
};

// Products Recommendations Section
const products_recommendations_chips = [
  "Often Bought Together",
  "You may also like",
  "Best seller",
  "New arrival",
  "Related products",
];

const products = {
  breakpoints: {
    0: {
      itemsToShow: 2.1,
      snapAlign: "center",
    },
    640: {
      itemsToShow: 4,
      snapAlign: "start",
    },
    1024: {
      itemsToShow: 5,
      snapAlign: "center",
    },
  },
  data: defaultProducts,
};

const product_listing_layout = ref("grid");

const product_listing_grid_cols = computed(() => {
  if (product_listing_layout.value === "grid" && isDesktop) {
    return 3;
  }

  if (product_listing_layout.value === "grid" && isMobile) {
    return 2;
  }

  if (product_listing_layout.value === "list") {
    return 1;
  }
});

// const renderTotalCount = () => {
//   if (24) {
//     if (searchResultStore.totalProductsFound <= 0) {
//       return `(No results)`;
//     }

//     if (
//       algoliaStore.totalNumOfFilteredResults > 0 &&
//       algoliaStore.totalNumOfFilteredResults !== algoliaStore.offsetBase
//     ) {
//       return `(Showing ${
//         algoliaStore.totalNumOfFilteredResults >= algoliaStore.offsetBase
//           ? `${searchResultStore.totalProductsFound}/${algoliaStore.totalNumOfFilteredResults} products)`
//           : algoliaStore.totalNumOfFilteredResults + " products)"
//       }`;
//     } else {
//       return `(Showing ${searchResultStore.totalProductsFound}/${algoliaStore.totalNumOfFilteredResults} products)`;
//     }
//   }
// };

const getChipLabel = (filterChip: string): string => {
  return useCapitalize(filterChip.replace(":", " - "));
};

const handleProductListingLayout = (layout: "grid" | "list") => {
  product_listing_layout.value = layout;
};

onUnmounted(() => {
  unwatch();
});
</script>
